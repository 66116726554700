import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { EnrichedService } from '../../types/types';

export type OnMoreInfoClickAction = ({
  service,
  referralInfo,
}: {
  service: EnrichedService;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnMoreInfoClickAction = ({
  navigateToNextPage,
}: {
  navigateToNextPage: NavigateToNextPageAction;
}): OnMoreInfoClickAction => {
  return async ({ service, referralInfo }) => {
    return navigateToNextPage({
      target: NavigationTarget.SERVICE_PAGE,
      service,
      referralInfo,
    });
  };
};
