import { WidgetViewModel } from '../../viewModel/viewModel';

export type OnDialogCloseAction = () => void;

export const createOnDialogCloseAction = ({
  widgetViewModel,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
}): OnDialogCloseAction => {
  return () => {
    widgetViewModel.dialogViewModel.isOpen = false;

    setProps({
      widgetViewModel: { ...widgetViewModel },
    });
  };
};
