import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  CLASSIC_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  GRID_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  STRIP_PRESET_ID,
} from '../../consts';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../types/types';

export const getServiceImagePositionDefaultValue = () =>
  ImagePositionOptions.LEFT;
export const getServiceImageShapeDefaultValue = (
  preset: any,
  isMobile: boolean,
): ImageShapeOptions => {
  if (isMobile) {
    return ImageShapeOptions.RECTANGLE;
  }
  switch (preset) {
    case STRIP_PRESET_ID:
    case STRIP_EDITOR_X_PRESET_ID:
      return ImageShapeOptions.ROUND;
    case BOOKINGS_MAIN_PAGE_PRESET_ID:
    case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
      return ImageShapeOptions.RECTANGLE;
    default:
      return ImageShapeOptions.SQUARE;
  }
};
export const getAlternateImageInfoSidesDefaultValue = () => false;
export const getTitleAlignmentDefaultValue = () => AlignmentOptions.CENTER;
export const getServiceImageSizeDefaultValue = (isMobile: boolean) =>
  isMobile ? ImageSizeOptions.MEDIUM : ImageSizeOptions.SMALL;
export const getTextAlignmentDefaultValue = (
  preset: any,
  isMobile: boolean,
) => {
  if (isMobile) {
    return AlignmentOptions.LEFT;
  }
  switch (preset) {
    case SINGLE_SERVICE_PRESET_ID:
    case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
      return AlignmentOptions.CENTER;
    default:
      return AlignmentOptions.LEFT;
  }
};
export const getMaxCardsPerRowDefaultValue = (isMobile: boolean) =>
  isMobile ? 1 : 3;

export const getGridCardSpacingDefaultValue = (isMobile: boolean) =>
  isMobile ? 20 : 32;

export const getStripCardSpacingDefaultValue = () => 0;

export const getServicesDividerWidthDefaultValue = () => 1;

export const getCardSpacingDefaultValue = () => 60;

export const getIsServiceImageVisibleDefaultValue = (
  preset: any,
  isMobile: boolean,
) => {
  if (isMobile) {
    return true;
  }
  switch (preset) {
    case STRIP_PRESET_ID:
    case STRIP_EDITOR_X_PRESET_ID:
      return false;
    default:
      return true;
  }
};

export const getServiceListLayoutDefaultValue = (
  preset: any,
  isMobile: boolean,
): ServiceListLayoutOptions => {
  if (isMobile) {
    return ServiceListLayoutOptions.GRID;
  }
  switch (preset) {
    case OVERLAPPING_PRESET_ID:
    case OVERLAPPING_EDITOR_X_PRESET_ID:
      return ServiceListLayoutOptions.OVERLAPPING;
    case STRIP_PRESET_ID:
    case STRIP_EDITOR_X_PRESET_ID:
      return ServiceListLayoutOptions.STRIP;
    case GRID_PRESET_ID:
    case GRID_EDITOR_X_PRESET_ID:
    case BOOKINGS_MAIN_PAGE_PRESET_ID:
    case BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID:
      return ServiceListLayoutOptions.GRID;
    default:
      return ServiceListLayoutOptions.CLASSIC;
  }
};

export const getFlipImageAndTextRatioDefaultValue = (preset: any): boolean => {
  switch (preset) {
    case SINGLE_SERVICE_PRESET_ID:
    case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
    case CLASSIC_PRESET_ID:
    case CLASSIC_EDITOR_X_PRESET_ID:
      return true;
    default:
      return false;
  }
};
