import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import settingsParams from '../../components/BookOnline/settingsParams';
import { EnrichedService, ServiceClickTarget } from '../../types/types';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';

export type OnServiceClickAction = ({
  service,
  referralInfo,
}: {
  service: EnrichedService;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnServiceClickAction = ({
  navigateToNextPage,
  flowAPI,
  onBookFlowStartAttempt,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  flowAPI: ControllerFlowAPI;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
}): OnServiceClickAction => {
  return async ({ service, referralInfo }) => {
    if (
      service.displayOnlyNoBookFlow ||
      service.type === ServiceType.COURSE ||
      flowAPI.settings.get(settingsParams.serviceClickTarget) ===
        ServiceClickTarget.SERVICE_PAGE
    ) {
      return navigateToNextPage({
        target: NavigationTarget.SERVICE_PAGE,
        service,
        referralInfo,
      });
    } else {
      return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
