import { ReferralInfo, ViewModeToWidgetNameMap } from '../../utils/bi/consts';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { EnrichedService } from '../../types/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  bookingsUoUBookNowClickSrc16Evid1190,
  bookingsUoUFlowBookInitialIntentSrc16Evid33,
} from '@wix/bi-logger-bookings-data/v2';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceListContext } from '../../context/createServiceListContext';

export type OnActionButtonClickAction = ({
  service,
  referralInfo,
}: {
  service: EnrichedService;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnActionButtonClickAction = ({
  navigateToNextPage,
  onBookFlowStartAttempt,
  flowAPI,
  serviceListContext,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
  flowAPI: ControllerFlowAPI;
  serviceListContext: ServiceListContext;
}): OnActionButtonClickAction => {
  return async ({ service, referralInfo }) => {
    const { viewMode } = serviceListContext;
    flowAPI.bi?.report(
      bookingsUoUFlowBookInitialIntentSrc16Evid33({
        widget_name: ViewModeToWidgetNameMap[viewMode!],
        bookingsServiceId: service.id!,
        serviceType: service.type!,
        isEligibleForFlowCompletion: !service.blockNavigationReason,
      }),
    );

    flowAPI.bi?.report(
      bookingsUoUBookNowClickSrc16Evid1190({ service_id: service.id! }),
    );

    if (service.type === ServiceType.COURSE || service.displayOnlyNoBookFlow) {
      return navigateToNextPage({
        target: NavigationTarget.SERVICE_PAGE,
        service,
        referralInfo,
      });
    } else {
      return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
