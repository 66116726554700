import { ISettingsGetter } from '@wix/tpa-settings';

import settingsParams from '../../components/BookOnline/settingsParams';
import {
  DockLocationOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  Point,
  ServiceListLayoutOptions,
} from '../../types/types';

export const dockLocationToFocalPointMapper = {
  [DockLocationOptions.TOP]: { x: 50, y: 0 },
  [DockLocationOptions.TOP_RIGHT]: { x: 100, y: 0 },
  [DockLocationOptions.RIGHT]: { x: 100, y: 50 },
  [DockLocationOptions.BOTTOM_RIGHT]: { x: 100, y: 100 },
  [DockLocationOptions.BOTTOM]: { x: 50, y: 100 },
  [DockLocationOptions.BOTTOM_LEFT]: { x: 0, y: 100 },
  [DockLocationOptions.LEFT]: { x: 0, y: 50 },
  [DockLocationOptions.TOP_LEFT]: { x: 0, y: 0 },
  [DockLocationOptions.MIDDLE]: { x: 50, y: 50 },
};

export type ServiceImageViewModel = {
  imageResize: ImageResizeOptions;
  aspectRatio: number;
  focalPoint: Point;
  isRoundGrid: boolean;
};

export const createServiceImageViewModel = ({
  settings,
  isMobile,
  serviceListLayout,
}: {
  settings: { get: ISettingsGetter };
  isMobile: boolean;
  serviceListLayout: ServiceListLayoutOptions;
}): ServiceImageViewModel => {
  const serviceImageShape = settings.get(settingsParams.serviceImageShape);
  const serviceImageResize = settings.get(settingsParams.serviceImageResize);
  const serviceImageDockLocation = settings.get(
    settingsParams.serviceImageDockLocation,
  );

  const imageShape = getImageShape(
    serviceListLayout,
    serviceImageShape,
    isMobile,
  );
  const imageResize = getImageResize(
    serviceListLayout,
    serviceImageShape,
    serviceImageResize,
  );
  const aspectRatio = getImageAspectRatio(
    serviceListLayout,
    serviceImageShape,
    isMobile,
  );

  const isRoundGrid =
    imageShape === ImageShapeOptions.ROUND &&
    serviceListLayout === ServiceListLayoutOptions.GRID;

  return {
    imageResize,
    aspectRatio,
    focalPoint:
      dockLocationToFocalPointMapper[
        serviceImageDockLocation as DockLocationOptions
      ],
    isRoundGrid,
  };
};

const getImageAspectRatio = (
  serviceListLayout: ServiceListLayoutOptions,
  serviceImageShape: ImageShapeOptions,
  isMobile: boolean,
): number => {
  if (isMobile) {
    return serviceImageShape === ImageShapeOptions.SQUARE ? 1 : 1.52;
  }

  if (serviceListLayout === ServiceListLayoutOptions.GRID) {
    return serviceImageShape === ImageShapeOptions.SQUARE ? 1 : 1.5;
  }

  return 0;
};

const getImageResize = (
  serviceListLayout: ServiceListLayoutOptions,
  serviceImageShape: ImageShapeOptions,
  serviceImageResize: ImageResizeOptions,
): ImageResizeOptions => {
  if (
    serviceListLayout === ServiceListLayoutOptions.OVERLAPPING ||
    serviceListLayout === ServiceListLayoutOptions.STRIP ||
    (serviceListLayout === ServiceListLayoutOptions.GRID &&
      serviceImageShape === ImageShapeOptions.ROUND)
  ) {
    return ImageResizeOptions.CROP;
  } else {
    return serviceImageResize;
  }
};

const getImageShape = (
  serviceListLayout: ServiceListLayoutOptions,
  serviceImageShape: ImageShapeOptions,
  isMobile: boolean,
): ImageShapeOptions => {
  if (
    isMobile ||
    serviceListLayout === ServiceListLayoutOptions.GRID ||
    serviceListLayout === ServiceListLayoutOptions.STRIP
  ) {
    return serviceImageShape;
  }

  return ImageShapeOptions.SQUARE;
};
