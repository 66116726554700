import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getUoULocale } from '../utils/locale/locale';
import { BusinessInfo } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';
import {
  getLanguage,
  getLocale,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { ViewMode } from '../types/types';
import type { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import {
  getBusinessInfo,
  BusinessPropertyName,
  isPropertyEnabled,
  getPropertyValue,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export type ServiceListContext = {
  isMultiServiceAppointmentEnabled: boolean;
  isPricingPlanInstalled: boolean;
  isAnywhereFlow: boolean;
  businessInfo: BusinessInfo;
  viewMode: ViewMode;
};

type CreateServiceListContextParams = {
  getBusinessResponse: GetAggregatedInfoResponse | GetBusinessResponse;
  flowAPI: ControllerFlowAPI;
  isPricingPlanInstalled: boolean;
  viewMode: ViewMode;
};

export const createServiceListContext = async ({
  flowAPI,
  getBusinessResponse,
  isPricingPlanInstalled,
  viewMode,
}: CreateServiceListContextParams): Promise<ServiceListContext> => {
  const {
    environment: { isPreview },
    controllerConfig: { wixCodeApi },
  } = flowAPI;
  const businessInfo =
    mapGetBusinessResponseToBusinessInfo(getBusinessResponse);

  businessInfo.language = getLanguage(wixCodeApi);
  businessInfo.regionalSettings = getLocale(wixCodeApi);

  const isMultiServiceAppointmentEnabled = isPropertyEnabled(
    getBusinessResponse,
    BusinessPropertyName.MULTI_SERVICES_APPOINTMENTS,
  );

  const isAnywhereFlowInd = await isAnywhereFlow(wixCodeApi, isPreview);

  return {
    isMultiServiceAppointmentEnabled,
    isPricingPlanInstalled,
    isAnywhereFlow: isAnywhereFlowInd,
    businessInfo,
    viewMode,
  };
};

const mapGetBusinessResponseToBusinessInfo = (
  getBusinessResponse: GetBusinessResponse | GetAggregatedInfoResponse,
): BusinessInfo => {
  const info = getBusinessInfo(getBusinessResponse);
  const { name, language, timeZone, locale } = info!;
  const defaultTimezone =
    getPropertyValue(
      getBusinessResponse,
      BusinessPropertyName.DEFAULT_TIMEZONE,
    ) || TimezoneType.BUSINESS;

  return {
    language: getUoULocale(language || ''),
    name: name || '',
    regionalSettings: locale!,
    timeZone: timeZone!,
    timezoneProperties: {
      defaultTimezone,
    },
  };
};
