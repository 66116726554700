import { ISettingsContextValue, ISettingsGetter } from '@wix/tpa-settings';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  CLASSIC_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  GRID_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  STRIP_PRESET_ID,
} from '../../consts';
import { Experiments } from '@wix/yoshi-flow-editor';

const presetsIds = [
  CLASSIC_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  STRIP_PRESET_ID,
  GRID_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
] as const;

export enum AccessibilityHtmlTags {
  MainHeading = 'h1',
  SecondaryHeading = 'h2',
  Paragraph = 'p',
}

export const mapPrestIdToNumber = (
  presetId: (typeof presetsIds)[number],
): number => presetsIds.indexOf(presetId);

export const isTabsDisplaySelected = (
  settings: { get: ISettingsGetter },
  experiments: Experiments,
): boolean => {
  const isServiceListMenuEnabled = experiments.enabled(
    'specs.bookings.serviceListMenuLayout',
  );
  return isServiceListMenuEnabled
    ? settings.get(settingsParams.isListFilterVisible) &&
        !settings.get(settingsParams.isMenuVisible)
    : settings.get(settingsParams.isListFilterVisible);
};

export const isMenuDisplaySelected = (
  settings: { get: ISettingsGetter },
  experiments: Experiments,
): boolean => {
  const isServiceListMenuEnabled = experiments.enabled(
    'specs.bookings.serviceListMenuLayout',
  );
  return (
    isServiceListMenuEnabled &&
    settings.get(settingsParams.isMenuVisible) &&
    settings.get(settingsParams.isListFilterVisible)
  );
};

export const mapNumberToPresetId = (num: number) => presetsIds[num];
