export const mergePresetIdToStyleParams = (
  stylesParams: any,
  presetId: string,
) => {
  return {
    ...stylesParams,
    numbers: { ...stylesParams.numbers, presetId },
  };
};

export const mergePresetIdToPublicData = (
  publicData: { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } },
  presetId: string,
) => {
  const COMPONENT = {
    ...publicData.COMPONENT,
    presetId,
  };
  return { APP: publicData.APP, COMPONENT };
};
