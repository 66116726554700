import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type OnPreferencesModalServiceSelected = (
  selectedService: Service,
) => void;

export const createOnPreferencesModalServiceSelectedAction = ({
  widgetViewModel,
  setProps,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}): OnPreferencesModalServiceSelected => {
  return async (selectedService) => {
    const { onServiceSelected } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );

    onServiceSelected({
      selectedService,
      experiments: flowAPI.experiments,
      httpClient: flowAPI.httpClient,
      getServicesPreferencesModalData: () =>
        widgetViewModel.dialogViewModel.data!,
      setServicesPreferencesModalData: (data) => {
        widgetViewModel.dialogViewModel.data = data;
        setProps({ widgetViewModel: { ...widgetViewModel } });
      },
    });
  };
};
