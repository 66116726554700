import { Category } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../../consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const DUMMY_CATEGORY_1 = 'dummy-category-id-1';
export const DUMMY_CATEGORY_2 = 'dummy-category-id-2';
export const DUMMY_CATEGORY_3 = 'dummy-category-id-3';

export const createDummyCategoriesDto = (
  flowAPI: ControllerFlowAPI,
  presetId: string,
): Category[] => {
  const {
    translations: { t },
  } = flowAPI;
  if (
    presetId === BOOKINGS_MAIN_PAGE_PRESET_ID ||
    presetId === BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
  ) {
    return [
      {
        id: DUMMY_CATEGORY_1,
        name: t('dummy.category-1.bookings.main.page.name'),
        sortOrder: 0,
      },
      {
        id: DUMMY_CATEGORY_2,
        name: t('dummy.category-2.bookings.main.page.name'),
        sortOrder: 1,
      },
      {
        id: DUMMY_CATEGORY_3,
        name: t('dummy.category-3.bookings.main.page.name'),
        sortOrder: 1,
      },
    ];
  }
  return [
    {
      id: DUMMY_CATEGORY_1,
      name: t('dummy.category-1.bookings.main.page.name'),
      sortOrder: 0,
    },
  ];
};
