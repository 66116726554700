// noinspection JSNonASCIINames

import { ServiceListSettings } from './appSettings';
import {
  AlignmentOptions,
  ButtonStyleOptions,
  DisplayServicesByOptions,
  DockLocationOptions,
  FilterLayoutOptions,
  FilterServicesByOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceClickTarget,
  ServiceListLayoutOptions,
} from '../../src/types/types';
import { cleanNulls } from '../utils';
import { SelectedResources } from '../types';

const getAppSettingsValue = <T>(appSettingsValue: T): T | null =>
  typeof appSettingsValue !== 'undefined' ? appSettingsValue : null;

export function mergeAppSettingsCustomsToSettingsParams(
  appSettings: ServiceListSettings,
) {
  const appSettingsCustom = {
    bookButtonStyle: getAppSettingsValue(
      appSettings.BUTTON_STYLE as ButtonStyleOptions,
    ),
    'bookButtonStyle▶︎m': getAppSettingsValue(
      appSettings.MOBILE_BUTTON_STYLE as ButtonStyleOptions,
    ),
    filterLayout: getAppSettingsValue(
      appSettings.CATEGORY_LAYOUT_OPTION as FilterLayoutOptions,
    ),
    'filterLayout▶︎m': getAppSettingsValue(
      appSettings.MOBILE_CATEGORY_LAYOUT_OPTION as FilterLayoutOptions,
    ),
    filterServicesBy: getAppSettingsValue(
      appSettings.CATEGORIES_TYPE as FilterServicesByOptions,
    ),
    displayServicesBy: getAppSettingsValue(
      appSettings.FILTER_BY as DisplayServicesByOptions,
    ),
    listFilterAlignment: getAppSettingsValue(
      appSettings.CATEGORY_ALIGNMENT as AlignmentOptions,
    ),
    textAlignment: getAppSettingsValue(
      appSettings.TEXT_ALIGNMENT as AlignmentOptions,
    ),
    'textAlignment▶︎m': getAppSettingsValue(
      appSettings.MOBILE_TEXT_ALIGNMENT as AlignmentOptions,
    ),
    titleAlignment: getAppSettingsValue(
      appSettings.MULTI_OFFERINGS_TITLE_ALIGNMENT as AlignmentOptions,
    ),
    serviceListLayout: getAppSettingsValue(
      appSettings.OFFERINGS_LIST_LAYOUT as ServiceListLayoutOptions,
    ),
    'serviceListLayout▶︎m': getAppSettingsValue(
      appSettings.MOBILE_OFFERING_LIST_LAYOUT as ServiceListLayoutOptions,
    ),
    serviceImagePosition: getAppSettingsValue(
      appSettings.IMAGE_POSITION_OPTION as ImagePositionOptions,
    ),
    serviceImageShape: getAppSettingsValue(
      appSettings.IMAGE_SHAPE_OPTION as ImageShapeOptions,
    ),
    'serviceImageShape▶︎m': getAppSettingsValue(
      appSettings.MOBILE_IMAGE_SHAPE_OPTION as ImageShapeOptions,
    ),
    serviceImageResize: getAppSettingsValue(
      appSettings.IMAGE_RESIZE_OPTION as ImageResizeOptions,
    ),
    'serviceImageResize▶︎m': getAppSettingsValue(
      appSettings.MOBILE_IMAGE_RESIZE_OPTION as ImageResizeOptions,
    ),
    serviceImageSize: getAppSettingsValue(
      appSettings.IMAGE_SIZE_OPTION as ImageSizeOptions,
    ),
    'serviceImageSize▶︎m': getAppSettingsValue(
      appSettings.MOBILE_IMAGE_SIZE_OPTION as ImageSizeOptions,
    ),
    serviceImageDockLocation: getAppSettingsValue(
      appSettings.PIN_LOCATION as DockLocationOptions,
    ),
    'serviceImageDockLocation▶︎m': getAppSettingsValue(
      appSettings.MOBILE_PIN_LOCATION as DockLocationOptions,
    ),
    selectedItems: getAppSettingsValue(
      appSettings.SELECTED_RESOURCES as SelectedResources,
    ),
    selectedLocations: getAppSettingsValue(
      appSettings.SELECTED_LOCATIONS as string[],
    ),
    serviceClickTarget: getAppSettingsValue(
      appSettings.SERVICE_CLICK_TARGET as ServiceClickTarget,
    ),
  };

  return cleanNulls(appSettingsCustom);
}
