import {
  getUrlQueryParamValue,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import type { IWixAPI } from '@wix/yoshi-flow-editor';

export type ResourceQueryParam = Partial<
  Record<
    BookingsQueryParams.STAFF | BookingsQueryParams.RESOURCE,
    string | undefined
  >
>;

export const getResourceQueryParam = (
  wixCodeApi: IWixAPI,
): ResourceQueryParam => {
  const preSelectedStaff = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.STAFF,
  );

  if (preSelectedStaff) {
    return { [BookingsQueryParams.STAFF]: preSelectedStaff };
  }
  const preSelectedResource = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.RESOURCE,
  );

  if (preSelectedResource) {
    return { [BookingsQueryParams.RESOURCE]: preSelectedResource };
  }
  return {};
};
