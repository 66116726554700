import { ISettingsGetter } from '@wix/tpa-settings';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookOnline/settingsParams';
import stylesParams from '../../components/BookOnline/stylesParams';
import {
  EnrichedService,
  ServiceListLayoutOptions,
  ServicesPagingMetadata,
} from '../../types/types';
import {
  CARD_MAX_WIDTH,
  CARD_MIN_WIDTH,
  GRID_CARD_MIN_WIDTH,
  MOBILE_CARD_MIN_WIDTH,
  SINGLE_CARD_PER_ROW,
} from '../../consts';
import { showExplorePlansForService } from '../../utils/serviceDetails/showExplorePlansForService';
import { getPaginationSEOMetadata } from '../../utils/pagination/pagination';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export type BodyViewModel = {
  spaceBetweenCards: number;
  maxCardsPerRow: number;
  cardMinWidth: number;
  cardMaxWidth?: number;
  showDivider: boolean;
  dividerWidth: string;
  imageRatio: string;
  atLeastOneServiceHasExplorePlansLink: boolean;
  loadMoreButtonText: string;
  loadMoreSeoHref: string;
  loadPreviousButtonText: string;
  loadPreviousSeoHref: string;
  maxServicesPerSection: number;
  focusCardIndex?: number;
  preSelectedCategory?: string;
};

export const createBodyViewModel = ({
  flowAPI,
  scale,
  imageAspectRatio,
  serviceListLayout,
  services,
  isPricingPlanInstalled,
  servicesPagingMetadata,
  groupedServicesByCategories,
}: {
  flowAPI: ControllerFlowAPI;
  scale: number;
  imageAspectRatio?: number;
  serviceListLayout: ServiceListLayoutOptions;
  services: EnrichedService[];
  isPricingPlanInstalled: boolean;
  servicesPagingMetadata: ServicesPagingMetadata;
  groupedServicesByCategories?: Map<string, EnrichedService[]>;
}): BodyViewModel => {
  const {
    settings,
    environment: { isMobile },
    controllerConfig: { wixCodeApi },
  } = flowAPI;

  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const isStripLayout = serviceListLayout === ServiceListLayoutOptions.STRIP;

  const spaceBetweenCards = getSpaceBetweenCards(serviceListLayout, settings);

  const maxCardsPerRow = !isGridLayout
    ? SINGLE_CARD_PER_ROW
    : settings.get(settingsParams.cardsPerRow);

  const cardMinWidth = getCardMinWidth(isMobile, isGridLayout, scale);
  const cardMaxWidth = !isMobile && isGridLayout ? CARD_MAX_WIDTH : undefined;

  const showDivider =
    isStripLayout && settings.get(settingsParams.isServicesDividerVisible);

  const dividerWidth = getDividerWidth(
    isStripLayout,
    settings.get(settingsParams.servicesDividerWidth),
  );

  const imageRatio = getImageAspectRatio(isGridLayout, imageAspectRatio);

  const atLeastOneServiceHasExplorePlansLink =
    isPricingPlanInstalled &&
    (settings.get(settingsParams.isExplorePlansVisible) === true ||
      flowAPI.controllerConfig.config.allStyles.some(
        (style) => style.booleans[stylesParams.isExplorePlansVisible.key!],
      )) &&
    services.some(showExplorePlansForService);

  const { loadMoreButtonText, loadPreviousButtonText } = getLoadingButtonsText({
    flowAPI,
    servicesPagingMetadata,
  });

  const { nextUrl, prevUrl } = getPaginationSEOMetadata(
    wixCodeApi,
    servicesPagingMetadata,
  );

  const maxServicesPerSection = groupedServicesByCategories
    ? Math.max(
        ...[...groupedServicesByCategories.values()].map((s) => s.length),
      )
    : services.length;

  const preSelectedCategory = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParams.CATEGORY,
  );

  return {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
    atLeastOneServiceHasExplorePlansLink,
    loadPreviousSeoHref: prevUrl,
    loadPreviousButtonText,
    loadMoreButtonText,
    loadMoreSeoHref: nextUrl,
    maxServicesPerSection,
    preSelectedCategory,
  };
};

const getSpaceBetweenCards = (
  serviceListLayout: ServiceListLayoutOptions,
  settings: { get: ISettingsGetter },
) => {
  switch (serviceListLayout) {
    case ServiceListLayoutOptions.GRID:
      return settings.get(settingsParams.gridCardsSpacing);
    case ServiceListLayoutOptions.STRIP:
      return settings.get(settingsParams.stripCardSpacing);
    case ServiceListLayoutOptions.CLASSIC:
    case ServiceListLayoutOptions.OVERLAPPING:
    default:
      return settings.get(settingsParams.cardSpacing);
  }
};

const getCardMinWidth = (
  isMobile: boolean,
  isGridLayout: boolean,
  scale: number,
) => {
  if (isMobile) {
    return MOBILE_CARD_MIN_WIDTH;
  }
  if (isGridLayout) {
    return GRID_CARD_MIN_WIDTH;
  }

  return CARD_MIN_WIDTH * scale;
};

const getImageAspectRatio = (
  isGridLayout: boolean,
  imageAspectRatio?: number,
) => {
  if (imageAspectRatio) {
    return `${Math.round(100 / imageAspectRatio)}%`;
  }

  return `${isGridLayout ? 100 : 0}%`;
};

const getDividerWidth = (
  isStripLayout: boolean,
  servicesDividerWidth: number,
): string => `${isStripLayout ? servicesDividerWidth : 0}px`;

export const getLoadingButtonsText = ({
  flowAPI: {
    experiments,
    settings,
    translations: { t },
  },
  servicesPagingMetadata,
}: {
  flowAPI: ControllerFlowAPI;
  servicesPagingMetadata: ServicesPagingMetadata;
}) => {
  const migrateServiceListToServiceV2 = experiments.enabled(
    'specs.bookings.migrateServiceListToServiceV2',
  );

  const showLoadPreviousButton =
    migrateServiceListToServiceV2 && servicesPagingMetadata.minPageLoaded > 1;

  const loadPreviousButtonText = showLoadPreviousButton
    ? settings.get(settingsParams.loadPreviousButtonText)
    : '';

  const showLoadMoreButton =
    migrateServiceListToServiceV2 &&
    servicesPagingMetadata.maxPageLoaded < servicesPagingMetadata.totalPages;

  const loadMoreButtonText = showLoadMoreButton
    ? settings.get(settingsParams.loadMoreButtonText)
    : '';

  return {
    loadPreviousButtonText,
    loadMoreButtonText,
  };
};
