import { ISettingsParamsTextKeys } from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import type { IWixAPI } from '@wix/yoshi-flow-editor';
import { isAnywhereUrl } from '@wix/wix-anywhere-api';

export type PublicDataOverrides = {
  displayServicesBy?: DisplayServicesByOptions;
  serviceListLayout?: ServiceListLayoutOptions;
  isTitleVisible?: boolean;
  services?: string[];
  categories?: string[];
  locations?: string[];
  titleText?: string;
};

export const mapPublicDataOverridesToPublicData = (
  serviceListTitle?: string,
): PublicDataOverrides & {
  titleText?: string;
} => {
  return {
    [ISettingsParamsTextKeys.titleText]: serviceListTitle,
  };
};

export const isAnywhereFlow = async (
  wixCodeApi: IWixAPI,
  isPreview?: boolean,
): Promise<boolean> => {
  if (isPreview) {
    return false;
  }
  const isAnywhere = await isAnywhereUrl({
    fullUrl: wixCodeApi.location.url,
    externalBaseUrl: wixCodeApi.location.baseUrl,
  });
  return isAnywhere || false;
};
