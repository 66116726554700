import { ReferralInfo } from '../../utils/bi/consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { openServicesPreferencesModalAction } from '../openServicesPreferencesModal/openServicesPreferencesModal';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { bookingsCantBookGroupsMessage } from '@wix/bi-logger-wixboost-ugc/v2';
import { OpenPremiumDialogAction } from '../openPremiumDialog/openPremiumDialog';
import { EnrichedService } from '../../types/types';
import { BookingsAPI } from '../../api/BookingsApi';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { BlockNavigationReason } from '../../utils/services/getBlockNavigationReason/getBlockNavigationReason';
import { ServiceListContext } from '../../context/createServiceListContext';
import { isServiceWithDynamicDuration } from '@wix/bookings-calendar-catalog-viewer-mapper';

export type OnBookFlowStartAttemptAction = ({
  service,
  referralInfo,
}: {
  service: EnrichedService;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnBookFlowStartAttemptAction = ({
  flowAPI,
  bookingsApi,
  openServicesPreferencesModal,
  navigateToNextPage,
  openPremiumDialog,
  serviceListContext,
}: {
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsAPI;
  openServicesPreferencesModal: openServicesPreferencesModalAction;
  openPremiumDialog: OpenPremiumDialogAction;
  navigateToNextPage: NavigateToNextPageAction;
  serviceListContext: ServiceListContext;
}): OnBookFlowStartAttemptAction => {
  const { isPricingPlanInstalled, isMultiServiceAppointmentEnabled } =
    serviceListContext;

  const isDynamicDurationUOUEnable = flowAPI.experiments.enabled(
    'specs.bookings.dynamicDurationUoU',
  );

  return async ({ service, referralInfo }) => {
    if (service.blockNavigationReason) {
      if (service.blockNavigationReason === BlockNavigationReason.NOT_PREMIUM) {
        bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt({
          service,
        });
      } else if (
        service.blockNavigationReason ===
        BlockNavigationReason.PRICING_PLAN_ERROR
      ) {
        bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt({
          isPricingPlanInstalled,
          service,
        });
      }
      flowAPI.bi?.report(
        bookingsCantBookGroupsMessage({
          referralInfo: ReferralInfo.WIDGET,
        }),
      );

      openPremiumDialog();
    } else {
      const isMultiServicesAppointmentsEnable =
        !service.dummy &&
        service.type === ServiceType.APPOINTMENT &&
        isMultiServiceAppointmentEnabled;

      const isServicePreferencesAppointmentsEnable =
        isDynamicDurationUOUEnable && isServiceWithDynamicDuration(service);

      if (
        isMultiServicesAppointmentsEnable ||
        isServicePreferencesAppointmentsEnable
      ) {
        openServicesPreferencesModal({ service });
      } else {
        return navigateToNextPage({
          service,
          referralInfo,
          target: NavigationTarget.CALENDAR_PAGE,
        });
      }
    }
  };
};
