import { IControllerConfig, IWixAPI } from '@wix/yoshi-flow-editor';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../consts';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const onLocationURLChange = (
  wixCodeApi: IWixAPI,
  callback: Function,
) => {
  const path = wixCodeApi.location.path;
  let currentResource = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.RESOURCE,
  );
  wixCodeApi.location.onChange((data: { path: string[] }) => {
    const newResource = getUrlQueryParamValue(
      wixCodeApi,
      BookingsQueryParams.RESOURCE,
    );
    if (data.path[0] === path[0] && currentResource !== newResource) {
      callback();
    }
    currentResource = newResource;
  });
};

export const getPresetId = (
  config: IControllerConfig,
  isEditorX: boolean,
): string =>
  config.publicData.COMPONENT?.presetId ||
  (isEditorX
    ? BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
    : BOOKINGS_MAIN_PAGE_PRESET_ID);
