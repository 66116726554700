import { createGraphqlClient, IGraphqlClient } from '@wix/graphql-client';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GraphQLFormattedError } from 'graphql';
import { HttpError } from '@wix/http-client';
import {
  QueryEventsDocument,
  WixCalendarEventsV3RecurrenceTypeEnumRecurrenceType,
} from '../gql/graphql';
import { getTodayLocalDateTimeStartOfDay } from '../utils/dateAndTime';

interface ConstructorParams {
  flowAPI: ControllerFlowAPI;
  currency?: string;
}

export class AutoGraphqlApi {
  private readonly autoGraphqlClient: IGraphqlClient;
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: ConstructorParams) {
    this.flowAPI = flowAPI;
    this.autoGraphqlClient = createGraphqlClient(flowAPI.httpClient);
  }

  private mapGraphqlErrorToHttp(
    gqlErrors: GraphQLFormattedError[],
  ): HttpError | null {
    const error = gqlErrors[0];
    if (error?.extensions) {
      return {
        response: {
          data: { message: error.message, details: error.extensions },
        },
      } as HttpError;
    } else {
      return null;
    }
  }

  public async queryEventsClassDays({
    scheduleIds,
  }: {
    scheduleIds: string[];
  }) {
    const { data, errors } = await this.autoGraphqlClient.query(
      QueryEventsDocument,
      {
        input: {
          recurrenceType: [
            WixCalendarEventsV3RecurrenceTypeEnumRecurrenceType.Master,
          ],
          fromLocalDate: getTodayLocalDateTimeStartOfDay(),
          query: {
            cursorPaging: {
              limit: 500,
            },
            filter: {
              $and: [
                { appId: '13d21c63-b5ec-5912-8397-c3a5ddb27a97' },
                { scheduleId: [...scheduleIds] },
                { type: 'CLASS' },
              ],
            },
          },
        },
      },
    );
    if (errors && errors.length > 0) {
      const httpError = this.mapGraphqlErrorToHttp(errors);
      if (httpError) {
        throw httpError;
      }
    }
    return { data };
  }
}
