import { ServiceType } from '@wix/bookings-uou-types';
import { NavigationTarget } from '../../actions/navigateToNextPage/consts';
import { ServiceIntent } from '../../consts';
import { ViewMode } from '../../types/types';

export const serviceListReferralInfo = 'service_list_widget';
export const WidgetNamePhase1 = 'widget-phase-one';
export const widgetName = 'service-list-widget';
export const pageName = 'service-list-page';

export enum WidgetName {
  WIDGET = 'service-list-widget',
  PAGE = 'service-list-page',
}

export const ViewModeToWidgetNameMap: Record<ViewMode, WidgetName> = {
  [ViewMode.PAGE]: WidgetName.PAGE,
  [ViewMode.WIDGET]: WidgetName.WIDGET,
}

export const BiServiceType = {
  [ServiceType.INDIVIDUAL]: 'ind',
  [ServiceType.GROUP]: 'class',
  [ServiceType.COURSE]: 'COURSE',
};

export enum ReferralInfo {
  WIDGET = 'widget',
  SERVICE_IMAGE = 'widget_image',
  SERVICE_TITLE = 'widget_title',
  BOOK_BUTTON = 'widget_button',
  EXPLORE_PLANS = 'explore_plans',
  MORE_INFO_BUTTON = 'widget_more_info_label',
}

export const ActionNames: Record<ServiceIntent | NavigationTarget, string> = {
  [ServiceIntent.BOOK_OFFERING]: 'navigate_to_calendar',
  [ServiceIntent.SHOW_DETAILS]: 'navigate_to_service_page',
  [NavigationTarget.CALENDAR_PAGE]: 'navigate_to_calendar',
  [NavigationTarget.SERVICE_PAGE]: 'navigate_to_service_page',
};

export enum ServiceListLoadButtonName {
  NEXT = 'load more',
  PREVIOUS = 'load previous',
}
