import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import {
  EnrichedService,
  FilterOption,
  FilterServicesByOptions,
} from '../../types/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import { BookingsQueryParams } from '@wix/bookings-catalog-calendar-viewer-utils';
import { BookingsAPI } from '../../api/BookingsApi';
import { getEnrichedServicesAndAvailability } from '../../utils/getEnrichedServicesAndAvailability/getEnrichedServicesAndAvailability';
import { getLoadingButtonsText } from '../../viewModel/bodyViewModel/bodyViewModel';
import { getPaginationSEOMetadata } from '../../utils/pagination/pagination';
import { groupServicesByCategories } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { isMenuDisplaySelected } from '../../utils/settings/settings';

export type OnFilterOptionSelectedAction = (
  selectedFilterOption: FilterOption,
) => void;

export const createOnFilterOptionSelectedAction = ({
  widgetViewModel,
  services: previousServices,
  setProps,
  flowAPI,
  bookingsApi,
  activeFeatures,
  isPricingPlanInstalled,
  serviceListContext,
}: {
  services: EnrichedService[];
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsAPI;
  activeFeatures: any;
  isPricingPlanInstalled: boolean;
  serviceListContext: any;
}): OnFilterOptionSelectedAction => {
  return async (selectedFilterOption: FilterOption) => {
    const {
      settings,
      controllerConfig: { wixCodeApi },
    } = flowAPI;
    const filterServicesBy = settings.get(settingsParams.filterServicesBy);

    if (
      widgetViewModel.filterOptions.find((option) => option.isSelected)?.id ===
      selectedFilterOption.id
    ) {
      return;
    }

    if (selectedFilterOption.id === ALL_SERVICES) {
      wixCodeApi.location.queryParams.remove([
        REQUESTED_CATEGORIES_URL_PARAM_NAME,
        BookingsQueryParams.LOCATION,
        BookingsQueryParams.PAGE,
      ]);
    } else if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
      wixCodeApi.location.queryParams.add({
        [REQUESTED_CATEGORIES_URL_PARAM_NAME]: selectedFilterOption.id,
        [BookingsQueryParams.PAGE]: '',
      });
    } else if (filterServicesBy === FilterServicesByOptions.LOCATIONS) {
      wixCodeApi.location.queryParams.add({
        [BookingsQueryParams.LOCATION]: selectedFilterOption.id,
        [BookingsQueryParams.PAGE]: '',
      });
    }

    const newFilterOptions = widgetViewModel.filterOptions.map(
      (filterOption: FilterOption) => ({
        ...filterOption,
        isSelected: filterOption.id === selectedFilterOption.id,
      }),
    );

    try {
      const { services, coursesAvailability, pagingMetadata } =
        await getEnrichedServicesAndAvailability({
          flowAPI,
          activeFeatures,
          isPricingPlanInstalled,
          isAnywhereFlow: serviceListContext.isAnywhereFlow,
          bookingsApi,
          selectedFilterOptionId: selectedFilterOption.id,
        });
      widgetViewModel.filterOptions = newFilterOptions;
      widgetViewModel.services = services;
      widgetViewModel.coursesAvailability = coursesAvailability;
      widgetViewModel.servicesPagingMetadata = pagingMetadata;
      widgetViewModel.groupedServicesByCategories = isMenuDisplaySelected(
        flowAPI.settings,
        flowAPI.experiments,
      )
        ? groupServicesByCategories(widgetViewModel.services)
        : undefined;

      const { loadPreviousButtonText, loadMoreButtonText } =
        getLoadingButtonsText({
          flowAPI,
          servicesPagingMetadata: widgetViewModel.servicesPagingMetadata,
        });

      const { nextUrl, prevUrl } = getPaginationSEOMetadata(
        flowAPI.controllerConfig.wixCodeApi,
        widgetViewModel.servicesPagingMetadata,
      );

      setProps({
        widgetViewModel: {
          ...widgetViewModel,
          bodyViewModel: {
            ...widgetViewModel.bodyViewModel,
            focusCardIndex: -1,
            loadPreviousButtonText,
            loadPreviousSeoHref: prevUrl,
            loadMoreButtonText,
            loadMoreSeoHref: nextUrl,
          },
        },
      });
    } catch (error) {
      console.error(error);
      widgetViewModel.errorMessage = flowAPI.translations.t(
        'error-state.change-tab.text',
      );
      setProps({
        widgetViewModel: { ...widgetViewModel },
      });
    }
  };
};
