import { FilterOption } from '../../types/types';
import { isTabsDisplaySelected } from '../../utils/settings/settings';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type HeaderViewModel = {
  isListFilterVisible: boolean;
};

export const createHeaderViewModel = (
  flowAPI: ControllerFlowAPI,
  filterOptions: FilterOption[],
): HeaderViewModel => {
  const isListFilterVisible =
    isTabsDisplaySelected(flowAPI.settings, flowAPI.experiments) &&
    filterOptions.length > 1;

  return {
    isListFilterVisible,
  };
};
