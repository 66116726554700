export function getTodayLocalDateTimeStartOfDay(): string {
  const dateTimeFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const dateString = dateTimeFormat.format(new Date());
  const shiftedDate = new Date(dateString);

  return getLocalDateTimeStartOfDay(shiftedDate);
}
// example - return 2020-10-05T00:00:00
export const getLocalDateTimeStartOfDay = (date: Date): string => {
  const localDate = getLocalDateFromDateTime(date);
  return `${localDate}T00:00:00`;
};

export const getLocalDateFromDateTime = (date: Date): string => {
  const addZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}`;
};
