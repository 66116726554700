import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { EnrichedService } from '../../types/types';

export const shouldShowDummyContent = ({
  services,
  flowAPI,
}: {
  services: CatalogServiceDto[] | EnrichedService[];
  flowAPI: ControllerFlowAPI;
}): boolean => {
  const { isEditor, isPreview } = flowAPI.environment;
  const hasServices = services && services.length > 0;
  return !hasServices && (isEditor || isPreview);
};
