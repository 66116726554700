import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

const BI_BOOKINGS_SRC = 16;

export function getPlatformBiLoggerDefaultsConfig(
  flowAPI: ControllerFlowAPI,
  widgetName: string,
) {
  const {
    biToken = '',
    businessId = '',
    isOwner,
    isOverEditor,
    ownerId,
    uid = '',
  } = prepareConfigParams(flowAPI);

  return {
    src: BI_BOOKINGS_SRC,
    widget_name: widgetName,
    businessId,
    biToken,
    is_owner: isOwner,
    is_over_editor: isOverEditor,
    ownerId,
    visitor_id: uid,
    bookingsPlatform: 'new_bookings_server',
  };
}

function prepareConfigParams(flowAPI: ControllerFlowAPI): any {
  const { isEditor, isPreview } = flowAPI.environment;
  const { platformAPIs } = flowAPI.controllerConfig;
  const visitorId = platformAPIs.bi?.visitorId || '';
  const ownerId = platformAPIs.bi?.ownerId || '';
  const memberId = flowAPI.controllerConfig.wixCodeApi.user.currentUser.id;
  const isOwner = ownerId === visitorId || ownerId === memberId;

  return {
    uid: visitorId,
    biToken: platformAPIs.bi?.biToken || '',
    businessId: flowAPI.controllerConfig.appParams.instanceId,
    isOwner,
    ownerId,
    isOverEditor: isEditor || isPreview,
  };
}
