import { bookingsUouExplorePlansClick } from '@wix/bi-logger-wixboost-ugc/v2';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ReferralInfo } from '../../utils/bi/consts';
import {
  navigateToPricingPlan,
  navigateToPricingPlanPreview,
} from '@wix/bookings-catalog-calendar-viewer-navigation';
import { EnrichedService } from '../../types/types';

export type NavigateToPricingPlanAction = (
  service: EnrichedService,
  referralInfo: ReferralInfo,
) => void;

export const createNavigateToPricingPlanAction =
  (flowApi: ControllerFlowAPI): NavigateToPricingPlanAction =>
  (service, referral) => {
    const planIds = service.payment?.pricingPlanIds!;

    const subtitle = flowApi.translations.t(
      'navigation.pricing-plans.subtitle',
      {
        serviceName: service.name,
      },
    );

    if (flowApi.environment.isPreview) {
      return navigateToPricingPlanPreview({
        wixCodeApi: flowApi.controllerConfig.wixCodeApi,
        subtitle,
      });
    }

    flowApi.bi?.report(
      bookingsUouExplorePlansClick({
        service_id: service.id!,
        type: service.type,
        isPendingApproval: !!service.onlineBooking?.requireManualApproval,
        referralInfo: referral,
      }),
    );
    return navigateToPricingPlan({
      wixCodeApi: flowApi.controllerConfig.wixCodeApi,
      redirectTo: {
        sectionId: 'Booking Calendar',
        relativePath: service.mainSlug?.name!,
      },
      planIds,
      subtitle,
      queryParams: {
        referral,
        service: service.id,
      },
    });
  };
